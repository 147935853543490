
import { defineComponent, ref } from "vue";
import { Chart, registerables } from "chart.js";
import DiagrammRepository from "../repositories/DiagrammRepository";
import { useRoute } from "vue-router";
import { Gemeinde } from "../types";
import GemeindeRepository from "../repositories/GemeindeRepository";

Chart.register(...registerables);

export default defineComponent({
  inheritAttrs: false,
  name: "DiagrammComponent",
  setup(): { [key: string]: any } {
    const route = useRoute();
    const success = route.params.success;
    const wahlId = route.params.wahlId;
    const gemeindeId = route.params.gemeindeId;
    const gemeinden = ref([]);
    const gemeinde = ref();
    const chartElement = ref<HTMLCanvasElement>();
    const votes = ref({});
    const graphDate = ref([]);
    const graphData = ref([]);
    const graphLabel = ref();
    const graphColor = ref();
    const dataSet = ref([]);
    let dateValue = ref();

    return {
      success,
      gemeindeId,
      gemeinde,
      gemeinden,
      wahlId,
      chartElement,
      chartInstance: ref<Chart<"bar"> | null>(null),
      votes,
      graphData,
      graphDate,
      graphLabel,
      graphColor,
      dateValue,
      dataSet,
    };
  },

  props: {
    sliders: {
      type: Object,
    },
  },
  mounted(): void {
    this.loadData();
  },

  methods: {
    loadData(): void {
      DiagrammRepository.getByGemeinde(
        this.wahlId,
        this.gemeindeId,
        this.dateValue
      ).then((votes: Array<any>) => {
        Object.keys(votes[Object.keys(votes)[0]].votesPerDay).forEach(
          (date) => {
            this.graphDate.push(new Date(date).toLocaleDateString("de-DE"));
          }
        );
        Object.values(votes).forEach((vote) => {
          this.dataSet.push({
            label: vote.name + (vote.partei ? " (" + vote.partei + ")" : ""),
            backgroundColor: "#" + vote.color,
            borderColor: "#" + vote.color,
            borderWidth: 3,
            data: Object.values(vote.votesPerDay),
          });
        });

        this.loadDiagramm();
      });

      GemeindeRepository.getByWahl(this.wahlId).then(
        (data: Array<Gemeinde>) => {
          data.forEach((gemeinden) => {
            if (gemeinden.id === this.gemeindeId) {
              this.gemeinde = gemeinden.name;
            }
          });

          data.forEach((gemeinden) => {
            if (gemeinden.id !== this.gemeindeId) {
              this.gemeinden.push({ id: gemeinden.id, name: gemeinden.name });
            }
          });
        }
      );
    },
    loadDiagramm(): void {
      if (!this.chartElement) {
        throw new Error("Chartelement konnte nicht geladen werden");
      }

      this.chartInstance = new Chart(this.chartElement, {
        type: "line",
        data: {
          labels: this.graphDate,
          datasets: this.dataSet,
        },

        options: {
          responsive: false,
          plugins: {
            legend: {
              labels: {
                padding: 20,
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return (
                    " " +
                    context.dataset.label +
                    " " +
                    context.dataset.data[context.dataIndex] +
                    " %"
                  );
                },
              },
            },
          },

          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          scales: {
            y: {
              min: 0,
              max: 100,
              title: {
                text: "Stimmen in %",
                display: true,
              },
            },
            x: {
              ticks: {
                autoSkip: false,
                maxRotation: 90,
              },
            },
          },
        },
      });
    },
  },
});
