
import { defineComponent, ref } from "vue";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "GemeindeComponent",
  setup(): { [key: string]: any } {
    const router = useRoute();
    const index = ref(0);
    const image = ref({});
    const images = ref([
      {
        id: 1,
        src: require("@/../assets/images/logos/header_oz.svg"),
        alt: "Ostfriesen Zeitung",
      },
      {
        id: 2,
        src: require("@/../assets/images/logos/header_on.svg"),
        alt: "Ostfriesische Nachrichten",
      },
      {
        id: 3,
        src: require("@/../assets/images/logos/header_ga.svg"),
        alt: "General Anzeiger",
      },
      {
        id: 3,
        src: require("@/../assets/images/logos/header_bz.svg"),
        alt: "Borkumer Zeitung",
      },
    ]);
    return {
      image,
      images,
      index,
    };
  },
  mounted() {
    this.switchImage(this.index);
  },
  methods: {
    switchImage(index) {
      this.image = this.images[index];
    },
  },
});
