import axios, { AxiosResponse } from "axios";
import { Gemeinde } from "../types";

export default new (class {
  getByWahl(wahlId: number): Promise<Array<Gemeinde>> {
    return axios
      .get(`/api/gemeinden/${wahlId}`)
      .then((response) => response.data);
  }
})();
