<template>
  <div class="container mt-3 mb-3 p-3">
    <h3>Wählen Sie eine Gemeinde aus</h3>
    <p>
      Hier können Sie eine Gemeinde auswählen, für die Sie das Wahlergebnis der
      Bürgermeisterwahl 2021 tippen möchten.
    </p>
  </div>
  <div class="container p-0 mb-5">
    <div class="row g-4">
      <div
        class="col col-12 col-lg-3 col-sm-6"
        v-for="(bezirk, id) in bezirke"
        :key="id"
      >
        <router-link :to="{ path: getKandidatenUrl(bezirk.id) }">
          <div class="gemeinde-box">
            <div class="box-headline">
              <h3>{{ bezirk.name }}</h3>
            </div>
            <img
              class="wappen"
              :src="require('@/../assets/images/wappen/' + bezirk.image)"
            />
            <div class="btn mt-3 w-100">auswählen</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Gemeinde } from "../types";
import GemeindeRepository from "../repositories/GemeindeRepository";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "GemeindeComponent",
  setup(): { [key: string]: any } {
    const router = useRoute();
    const wahlId = router.params.wahlId;
    const bezirke = ref([]);
    return {
      wahlId,
      bezirke,
    };
  },
  mounted(): void {
    GemeindeRepository.getByWahl(this.wahlId).then((data: Array<Gemeinde>) => {
      this.bezirke = data;
    });
  },
  methods: {
    getKandidatenUrl(gemeindeId): string {
      return `/kandidaten/${this.wahlId}/${gemeindeId}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.gemeinde-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 8px #d0d0d069;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  height: 100%;
}
</style>
