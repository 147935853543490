import axios from "axios";
import { Kandidat } from "../types";

export default class KandidatRepository {
  public static getByGemeinde(
    wahlId: number,
    gemeindeId: number
  ): Promise<Kandidat[]> {
    return axios
      .get<Kandidat[]>(
        `/api/kandidaten/${wahlId}/${gemeindeId}`
      )
      .then((response) => response.data);
  }
}
