
import { defineComponent, ref } from "vue";
import { Gemeinde } from "../types";
import GemeindeRepository from "../repositories/GemeindeRepository";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "GemeindeComponent",
  setup(): { [key: string]: any } {
    const router = useRoute();
    const wahlId = router.params.wahlId;
    const bezirke = ref([]);
    return {
      wahlId,
      bezirke,
    };
  },
  mounted(): void {
    GemeindeRepository.getByWahl(this.wahlId).then((data: Array<Gemeinde>) => {
      this.bezirke = data;
    });
  },
  methods: {
    getKandidatenUrl(gemeindeId): string {
      return `/kandidaten/${this.wahlId}/${gemeindeId}`;
    },
  },
});
