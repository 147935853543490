import axios from "axios";
import { Ergebnis } from "../types";

export default class DiagrammRepository {
  public static getByGemeinde(
    wahlId: number,
    gemeindeId: number,
    dateValue: Date
  ): Promise<Ergebnis[]> {
    return axios
      .get<Ergebnis[]>("/api/ergebnis/" + gemeindeId, {
        params: { datum: dateValue },
      })
      .then((response) => response.data);
  }
}
