
import { defineComponent, reactive, ref } from "vue";
import KandidatRepository from "../repositories/KandidatRepository";
import { useRoute } from "vue-router";
import VoteRepository from "../repositories/VoteRepository";
import router from "../router";
import GemeindeRepository from "../repositories/GemeindeRepository";
import { Gemeinde } from "../types";

export default defineComponent({
  name: "KandidatenComponent",
  setup(): { [key: string]: any } {
    const route = useRoute();
    const totalValue = ref(0);
    const gemeinde = ref();
    const loading = ref(true);
    const clickLock = ref(false);
    const gemeindeId = route.params.gemeindeId;
    const wahlId = route.params.wahlId;
    const isActive = ref(true);
    let key = ref(0);
    const kandidaten = ref([]);
    const voteForm = reactive({
      vorname: "",
      nachname: "",
      email: "",
      agb: false,
    });
    const voteRules = reactive({
      email: [
        {
          type: "email",
          message: "Bitte geben Sie eine gültige E-Mail Adresse ein",
          trigger: "blur",
        },
      ],
      agb: {
        required: true,
        message: "Um am Gewinnspiel teilzunehmen musst du die Teilnahmebedingungen akzeptieren",
        trigger: "blur",
      },
    });
    const formRef = ref<HTMLFormElement>();

    return {
      gemeinde,
      gemeindeId,
      wahlId,
      totalValue,
      voteRules,
      kandidaten,
      isActive,
      voteForm,
      formRef,
      clickLock,
      loading,
      key,
    };
  },

  data() {
    return {
      sliders: reactive<Record<number, number>>({}),
    };
  },

  mounted(): void {
    VoteRepository.getActive(this.wahlId).then((active) => {
      if (!+active[0].active) {
        this.isActive = false;
      }
    });

    KandidatRepository.getByGemeinde(this.wahlId, this.gemeindeId)
      .then((kandidaten) => {
        this.kandidaten = kandidaten;

        this.kandidaten.forEach((data) => {
          this.sliders[data.id] = 0;
        });
      })
      .finally(() => {
        this.loading = false;
      });

    GemeindeRepository.getByWahl(this.wahlId).then((data: Array<Gemeinde>) => {
      this.gemeinden = data;

      data.forEach((gemeinden) => {
        if (gemeinden.id === this.gemeindeId) {
          this.gemeinde = gemeinden.name;
        }
      });
    });

    this.changeSlider(1);
  },
  computed: {
    buttonDisabled(): boolean {
      return !this.voteForm.agb || this.clickLock || !this.isActive;
    },
  },
  methods: {
    changeSlider(data): void {
      this.key++;
      this.totalValue = Object.values(this.sliders).reduce((a, b) => a + b, 0);

      if (this.totalValue > 100) {
        this.sliders[data] -= this.totalValue - 100;
        this.totalValue = Object.values(this.sliders).reduce((a, b) => a + b, 0);

        this.$notify({
          title: "Warnung",
          message: "Die Summe der abgegebenen Stimmen übersteigt 100%",
          type: "warning",
        });
      }
    },

    formatTooltip(val) {
      return val + " %";
    },

    submitForm(): void {
      this.clickLock = true;
      this.formRef.validate((valid) => {
        if (!valid) {
          return false;
        }

        if (this.totalValue < 100) {
          this.$notify({
            title: "Warnung",
            message: "Die Summe der abgegebenen Stimmen muss 100% betragen",
            type: "warning",
          });
          this.clickLock = false;
          return false;
        }

        const voteResult = {
          vorname: this.voteForm.vorname,
          nachname: this.voteForm.nachname,
          email: this.voteForm.email,
          agb: this.voteForm.agb,
          votes: this.sliders,
          gemeindeId: this.gemeindeId,
        };

        VoteRepository.setVote(this.wahlId, voteResult)
          .then((data) => {
            if (Boolean(data) === true) {
              router.push({
                name: "Diagramm",
                params: {
                  wahlId: 1,
                  gemeindeId: this.gemeindeId,
                  success: "1",
                },
              });
            } else {
              this.isActive = false;
            }
          })
          .finally(() => {
            this.clickLock = false;
          });
      });
    },
  },
});
