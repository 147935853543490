<template>
  <div class="container mt-3 mb-3 p-3">
    <h3>Kandidaten für die {{ gemeinde }}</h3>
    <p>
      Viele wissen mehr als wenige. So kurz vor den Bürgermeisterwahlen möchten wir gerne die „Schwarmintelligenz“ unserer Leserinnen und Leser anzapfen und laden Sie ein zu
      unserem Wahltipp. Unser Ziel ist es, so exakt wie möglich das Ergebnis der Wahl am 12. September vorherzusagen. Wenn Sie Ihre Prognose abgeben möchten, dann betätigen Sie bei
      allen Kandidatinnen und Kandidaten der jeweiligen Stadt oder Gemeinde die Schieberegler (oder geben rechts die Prozentzahl an).
      <br /><br />Sie können selbstverständlich anonym abstimmen. Wenn Sie allerdings für die zutreffendste Wahlprognose ein iPad, also einen Tablet-Computer, gewinnen möchten,
      dann geben Sie bitte Ihren Namen und die Mailadresse an. <br /><br />Die Abstimmung endet am Freitag, 10. September. Zwischenstände sehen Sie, wenn Sie im Menü oben auf
      „Aktueller Stand“ klicken. <br /><br />Bitte beachten Sie: Es geht nicht darum, Ihren Lieblingskandidaten zu pushen, sondern möglichst realistische Prognosen zu erstellen.
      Darum darf jeder Teilnehmer auch nur ein Mal für jede Kommune abstimmen.
    </p>
  </div>

  <div class="container p-0" v-if="!isActive">
    <div class="alert alert-danger" role="alert">
      Die Wahl ist bereits beendet. Eine Abstimmung ist nicht mehr möglich.<br />
      <router-link :to="{ path: '/ergebnis/' + wahlId + '/' + gemeindeId }">Zum Endergebnis dieses Wahltipps gelangen Sie hier.</router-link>
    </div>
  </div>

  <div class="container box">
    <div class="d-flex align-items-center justify-content-center py-5" v-if="loading">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="row p-4 border" v-for="(kandidat, id) in kandidaten" :key="id">
      <div class="col col-12 col-lg-3 d-flex flex-row align-items-center">
        <img :src="require('@/../assets/images/kandidaten/' + kandidat.image)" class="cover me-4" />
        <div>
          <b>{{ kandidat.name }}</b
          ><br />
          {{ kandidat.partei }}
        </div>
      </div>
      <div class="col col-12 col-lg-9 d-flex align-items-center">
        <el-slider
          class="w-100 m-0"
          v-model.number="sliders[kandidat.id]"
          :max="100"
          :step="1"
          @change="changeSlider(kandidat.id)"
          show-input
          :disabled="(totalValue >= 100 && (sliders[kandidat.id] <= 0 || !sliders[kandidat.id])) || !isActive"
          :format-tooltip="formatTooltip"
          :key="key"
        ></el-slider>
      </div>
    </div>
    <div class="row border" v-if="!loading">
      <div class="col col-12 col-md-6 order-1 order-md-0 d-flex flex-column justify-content-start mt-3">
        <el-form :model="voteForm" label-position="left" class="" label-width="100px" :rules="voteRules" ref="formRef">
          <el-form-item class="mb-4 mt-2 ms-md-3 ms-0" label="" prop="agb">
            <el-switch v-model="voteForm.agb" class="me-3"></el-switch>
            <a href="##" class="agb" target="popup" onclick="window.open('/teilnahmebedingungen','popup','width=600,height=600'); return false;">
              Teilnahmebedingungen akzeptieren</a
            >
          </el-form-item>
          <el-button type="primary" @click="submitForm()" class="w-100 mb-3 ms-md-3 ms-0" :disabled="buttonDisabled">Tipp abgeben</el-button>
        </el-form>
      </div>
      <div class="col col-12 col-md-6 order-0 order-md-1 d-flex justify-content-end pe-4 mt-4">
        <p class="">Noch zu vergebene Prozente: {{ 100 - totalValue }} %</p>
      </div>
    </div>
  </div>

  <div class="container box mt-3 mb-5 p-5">
    <div class="row" v-if="kandidaten.length > 0">
      <div class="col col-12 col-md-8">
        <h3>Um am Gewinnspiel teilzunehmen, füllen Sie bitte das Formular aus</h3>

        <el-form :model="voteForm" label-position="left" label-width="100px" class="mt-4" :rules="voteRules" ref="formRef">
          <el-form-item label="Vorname" prop="vorname">
            <el-input v-model="voteForm.vorname"></el-input>
          </el-form-item>
          <el-form-item label="Nachname" prop="nachname">
            <el-input v-model="voteForm.nachname"></el-input>
          </el-form-item>
          <el-form-item label="E-Mail" prop="email">
            <el-input type="email" v-model="voteForm.email"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="col col-12 col-md-4 d-flex align-items-center justify-content-center">
        <img :src="require('@/../assets/images/ipad.png')" height="250" class="mt-5 mt-md-0" />
      </div>
    </div>
    <div class="row" v-else>
      <div class="col">Für diesen Bezirk stehen keine Kandidaten zur Wahl</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import KandidatRepository from "../repositories/KandidatRepository";
import { useRoute } from "vue-router";
import VoteRepository from "../repositories/VoteRepository";
import router from "../router";
import GemeindeRepository from "../repositories/GemeindeRepository";
import { Gemeinde } from "../types";

export default defineComponent({
  name: "KandidatenComponent",
  setup(): { [key: string]: any } {
    const route = useRoute();
    const totalValue = ref(0);
    const gemeinde = ref();
    const loading = ref(true);
    const clickLock = ref(false);
    const gemeindeId = route.params.gemeindeId;
    const wahlId = route.params.wahlId;
    const isActive = ref(true);
    let key = ref(0);
    const kandidaten = ref([]);
    const voteForm = reactive({
      vorname: "",
      nachname: "",
      email: "",
      agb: false,
    });
    const voteRules = reactive({
      email: [
        {
          type: "email",
          message: "Bitte geben Sie eine gültige E-Mail Adresse ein",
          trigger: "blur",
        },
      ],
      agb: {
        required: true,
        message: "Um am Gewinnspiel teilzunehmen musst du die Teilnahmebedingungen akzeptieren",
        trigger: "blur",
      },
    });
    const formRef = ref<HTMLFormElement>();

    return {
      gemeinde,
      gemeindeId,
      wahlId,
      totalValue,
      voteRules,
      kandidaten,
      isActive,
      voteForm,
      formRef,
      clickLock,
      loading,
      key,
    };
  },

  data() {
    return {
      sliders: reactive<Record<number, number>>({}),
    };
  },

  mounted(): void {
    VoteRepository.getActive(this.wahlId).then((active) => {
      if (!+active[0].active) {
        this.isActive = false;
      }
    });

    KandidatRepository.getByGemeinde(this.wahlId, this.gemeindeId)
      .then((kandidaten) => {
        this.kandidaten = kandidaten;

        this.kandidaten.forEach((data) => {
          this.sliders[data.id] = 0;
        });
      })
      .finally(() => {
        this.loading = false;
      });

    GemeindeRepository.getByWahl(this.wahlId).then((data: Array<Gemeinde>) => {
      this.gemeinden = data;

      data.forEach((gemeinden) => {
        if (gemeinden.id === this.gemeindeId) {
          this.gemeinde = gemeinden.name;
        }
      });
    });

    this.changeSlider(1);
  },
  computed: {
    buttonDisabled(): boolean {
      return !this.voteForm.agb || this.clickLock || !this.isActive;
    },
  },
  methods: {
    changeSlider(data): void {
      this.key++;
      this.totalValue = Object.values(this.sliders).reduce((a, b) => a + b, 0);

      if (this.totalValue > 100) {
        this.sliders[data] -= this.totalValue - 100;
        this.totalValue = Object.values(this.sliders).reduce((a, b) => a + b, 0);

        this.$notify({
          title: "Warnung",
          message: "Die Summe der abgegebenen Stimmen übersteigt 100%",
          type: "warning",
        });
      }
    },

    formatTooltip(val) {
      return val + " %";
    },

    submitForm(): void {
      this.clickLock = true;
      this.formRef.validate((valid) => {
        if (!valid) {
          return false;
        }

        if (this.totalValue < 100) {
          this.$notify({
            title: "Warnung",
            message: "Die Summe der abgegebenen Stimmen muss 100% betragen",
            type: "warning",
          });
          this.clickLock = false;
          return false;
        }

        const voteResult = {
          vorname: this.voteForm.vorname,
          nachname: this.voteForm.nachname,
          email: this.voteForm.email,
          agb: this.voteForm.agb,
          votes: this.sliders,
          gemeindeId: this.gemeindeId,
        };

        VoteRepository.setVote(this.wahlId, voteResult)
          .then((data) => {
            if (Boolean(data) === true) {
              router.push({
                name: "Diagramm",
                params: {
                  wahlId: 1,
                  gemeindeId: this.gemeindeId,
                  success: "1",
                },
              });
            } else {
              this.isActive = false;
            }
          })
          .finally(() => {
            this.clickLock = false;
          });
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.cover {
  object-fit: cover;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

.kandidaten-wrapper {
  width: 320px;
}
:deep(.el-slider--with-input) {
  height: 38px;
}

:deep(.el-form-item__content) {
  margin-left: 0 !important;
  line-height: unset !important;
}

:deep(.el-slider .el-input) {
  &:after {
    position: relative;
    top: -31px;
    left: 77px;
    content: "%";
    z-index: 5;
  }
}

:deep(.lds-spinner) {
  color: #000000;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
:deep(.lds-spinner div) {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
:deep(.lds-spinner div:after) {
  content: "|";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
:deep(.lds-spinner div:nth-child(1)) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
:deep(.lds-spinner div:nth-child(2)) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
:deep(.lds-spinner div:nth-child(3)) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
:deep(.lds-spinner div:nth-child(4)) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
:deep(.lds-spinner div:nth-child(5)) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
:deep(.lds-spinner div:nth-child(6)) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
:deep(.lds-spinner div:nth-child(7)) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
:deep(.lds-spinner div:nth-child(8)) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
:deep(.lds-spinner div:nth-child(9)) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
:deep(.lds-spinner div:nth-child(10)) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
:deep(.lds-spinner div:nth-child(11)) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
:deep(.lds-spinner div:nth-child(12)) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
