import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import "bootstrap";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "./styles/app.scss";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

createApp(App).use(router).use(ElementPlus).mount("#app");
