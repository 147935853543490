<template>
  <div class="container">
    <div class="row">
      <div class="row">
        <h2>Diese Seite konnte nicht gefunden werden.</h2>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFoundComponent",
});
</script>

<style lang="scss" scoped></style>
