<template>
  <div class="container-fluid header">
    <a href="https://www.oz-online.de/" target="_blank"
      ><img :src="image.src" alt="Logo" />
    </a>
  </div>

  <div class="sticky-top d-flex align-items-center bg-white shadow-sm mb-5">
    <div class="navbar navbar-expand sub-nav">
      <div class="container">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link me-4 px-0" to="/gemeinden/1"
              >Gemeinden</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link me-4 px-0" to="/ergebnis/1/1"
              >Aktueller Stand</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-view :key="$route.fullPath" />
  <div class="container-fluid footer">
    <a class="text-white" href="https://www.oz-online.de/-der-verlag/impressum"
      >Impressum</a
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "GemeindeComponent",
  setup(): { [key: string]: any } {
    const router = useRoute();
    const index = ref(0);
    const image = ref({});
    const images = ref([
      {
        id: 1,
        src: require("@/../assets/images/logos/header_oz.svg"),
        alt: "Ostfriesen Zeitung",
      },
      {
        id: 2,
        src: require("@/../assets/images/logos/header_on.svg"),
        alt: "Ostfriesische Nachrichten",
      },
      {
        id: 3,
        src: require("@/../assets/images/logos/header_ga.svg"),
        alt: "General Anzeiger",
      },
      {
        id: 3,
        src: require("@/../assets/images/logos/header_bz.svg"),
        alt: "Borkumer Zeitung",
      },
    ]);
    return {
      image,
      images,
      index,
    };
  },
  mounted() {
    this.switchImage(this.index);
  },
  methods: {
    switchImage(index) {
      this.image = this.images[index];
    },
  },
});
</script>

<style lang="scss">
@import "styles/app";

a {
  text-decoration: none !important;
}

.header {
  background: white;
  border-bottom: 1px solid;
  padding-top: 32px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.footer {
  position: absolute;
  bottom: 0;
  background: $primary;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.wappen {
  height: 100px;
  margin-bottom: 16px;
}

.box-headline {
  height: 100%;
  margin-bottom: 16px;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media (max-width: 768px) {
    padding-left: 16px !important;
  }
}

.sub-nav {
  width: 100%;
  max-height: 56px;
  white-space: nowrap;
  overflow-x: auto;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  background-color: white;

  a {
    color: #292929;
    font-size: 16px;
    line-height: 2.5;

    &:hover,
    &:focus,
    &:focus,
    &:active {
      color: inherit;
    }
  }
}

.nav-item {
  .active {
    border-bottom: 2px solid $primary;
    height: 100%;
    margin-bottom: -2px;
  }
}

.nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
