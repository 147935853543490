<template>
  <div class="p-5">
    <h1>Teilnahmebedingungen</h1>

    <b>Allgemeine Teilnahmebedingungen:</b><br />
    Mit der Teilnahme an dem Gewinnspiel akzeptiert der Teilnehmer diese
    Teilnahmebedingungen. <br /><br />
    <b>Veranstalter:</b> <br />
    Veranstalter des Wahltipps ist die ZGO Zeitungsgruppe Ostfriesland GmbH,
    Maiburger Straße 8, 26789 Leer. Ansprechpartner erreichen Sie ausschließlich
    bei der ZGO Zeitungsgruppe Ostfriesland GmbH unter der Telefonnummer
    0491/9790-0. <br /><br />

    <b>Zur Teilnahme berechtigte Personen:</b>
    <br />Personen, die das 18. Lebensjahr noch nicht vollendet haben (die also
    nicht wahlberechtigt sind), dürfen nicht am Wahltipp teilnehmen. Gewinne
    werden nicht an Minderjährige unter 18 Jahren ausgeschüttet. Teilnehmen darf
    jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland, ausgenommen
    Mitarbeiter der Zeitungsgruppe Ostfriesland, verbundener Unternehmen, sowie
    deren Angehörige. <br /><br /><b>Teilnahme:</b><br />
    Die für die Teilnahme erforderlichen Daten (Vorname, Nachname,
    E-Mail-Adresse) sind vollständig und richtig anzugeben. Die ZGO
    Zeitungsgruppe Ostfriesland GmbH, sowie deren Angestellte und Vertreter
    übernehmen keine Gewähr für die problemlose Nutzung des Internets.<br /><br />
    <b>Gewinn:</b>
    <br />
    Verzichtet ein Teilnehmer auf den Gewinn, so rückt in diesem Fall an seine
    Stelle der Teilnehmer mit dem nächstbesten Ergebnis nach und so fort. Eine
    Barauszahlung des Gewinnwertes und ein Umtausch des Gewinns sind
    ausgeschlossen. Der Gewinn ist nicht übertragbar. Für die Abführung von ggf.
    auf den Gewinn anfallenden Steuern ist der jeweilige Gewinner selbst
    verantwortlich. Mit der Aushändigung bzw. Übergabe der Gewinne wird die ZGO
    Zeitungsgruppe Ostfriesland GmbH, deren Angestellte und Vertreter von allen
    etwaigen Verpflichtungen aus diesem Gewinnspiel frei. Des Weiteren findet
    keinerlei Haftung für Schäden, die sich im Zusammenhang mit der Annahme der
    Gewinne und/oder sich im Nachgang ergeben können seitens der ZGO
    Zeitungsgruppe Ostfriesland GmbH, Angestellter oder Vertreter statt. Für
    Sach- und Rechtsmängel der verlosten Gewinne übernimmt die ZGO
    Zeitungsgruppe Ostfriesland GmbH, deren Angestellte und Vertreter keinerlei
    Haftung oder Gewährleistung. Ferner werden keine Kosten, die den Gewinnern
    durch die Entgegennahme bzw. Einlösung des jeweiligen Gewinns entstehen
    übernommen. <br /><br />
    <b
      >Ermittlung und Information des Gewinners / Inanspruchnahme des
      Gewinns:</b
    >
    <br />Unter allen Teilnehmern wird der Gewinner ermittelt. Gewinner ist, wer
    in seiner Wahlprognose für eine der acht Bürgermeisterwahlen dem
    tatsächlichen Ergebnis am nächsten kommt. Der Gewinner wird per E-Mail
    benachrichtigt. Die Teilnehmer erklären sich damit einverstanden, dass Name
    und Wohnort im Fall des Gewinns im Rahmen der Gewinnspiel-Auflösung in allen
    Titeln der ZGO Zeitungsgruppe Ostfriesland GmbH sowie deren Websites und
    Facebook-/Instagram-Fanpages veröffentlicht werden. <br /><br />

    <b>Vorzeitige Beendigung, Ausschluss:</b><br />
    1. Die ZGO Zeitungsgruppe Ostfriesland GmbH behält sich das Recht vor, das
    Gewinnspiel anzupassen, zu ändern oder abzubrechen, falls die Notwendigkeit
    besteht. Dies gilt insbesondere, falls eine ordnungsgemäße Durchführung aus
    technischen oder rechtlichen Gründen nicht gewährleistet werden kann. Daraus
    lassen sich keine Ansprüche auf Haftung bzw. Schadensersatz seitens der
    Teilnehmer ableiten. Den Teilnehmern stehen in einem solchen Fall keine
    Ansprüche gegen den Veranstalter zu. <br />2. Der Veranstalter behält sich
    vor, Teilnehmer von der Teilnahme an der Aktion auszuschließen, etwa bei
    Verstößen gegen die Teilnahmebedingungen. <br />3. Der Veranstalter behält
    sich vor, die Teilnahmebedingungen zu ändern oder den zeitlichen Rahmen
    auszuweiten oder zu begrenzen oder den Inhalt der Gewinne zu variieren,
    sofern hierfür sachliche Gründe vorliegen und die Änderung angemessen ist.
    Einwendungen dagegen sind nicht möglich. <br /><br />
    <b>Haftung:</b> <br />
    Die Haftung des Veranstalters ist, soweit gesetzlich zulässig,
    ausgeschlossen.
    <br /><br />
    <b>Datenschutz:</b> <br />Personenbezogene Daten werden von den Teilnehmern
    erfasst und nur zur Benachrichtigung und zum Versand der Gewinne genutzt.
    Bei Kooperationsverlosungen werden die Daten der Teilnehmer an den
    Veranstalter weitergegeben. Anderweitig erfolgt keine Weitergabe der Daten
    an Dritte. Ansonsten werden die Daten von der ZGO Zeitungsgruppe
    Ostfriesland GmbH ausschließlich zur Durchführung und Abwicklung des
    Gewinnspiels verarbeitet und anschließend gelöscht. Es werden keine Namen
    von den Teilnehmern gespeichert. Den Teilnehmern stehen gesetzliche
    Auskunfts-, Änderungs- und Widerrufsrechte zu.<br /><br />
    <b>Sonstiges</b> <br />1. Sollten einzelne Bestimmungen dieser
    Teilnahmebedingungen ungültig sein, bleibt die Gültigkeit der übrigen
    Bestimmungen unberührt. <br />2. Es gilt deutsches Recht. Der Rechtsweg ist
    ausgeschlossen. <br />3. Mit der Teilnahme erklärt sich der Teilnehmer mit
    diesen Teilnahmebedingungen einverstanden.
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "BedingungenPage",
});
</script>

<style lang="scss"></style>
