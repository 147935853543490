import axios from "axios";
import { Teilnehmer, Wahl } from "../types";

export default class VoteRepository {
  public static setVote(wahlId: number, votes: any): Promise<Teilnehmer[]> {
    return axios.post<Teilnehmer[]>("/api/insertUser", votes, {}).then((response) => response.data);
  }

  public static getActive(wahlId: number): Promise<Wahl[]> {
    return axios.get<Wahl[]>(`/api/isActive/${wahlId}`).then((response) => response.data);
  }
}
