import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import GemeindePage from "../views/GemeindePage.vue";
import KandidatPage from "../views/KandidatPage.vue";
import DiagrammPage from "../views/DiagrammPage.vue";
import NotFound from "../views/NotFound.vue";
import BedingungenPage from "../views/BedingungenPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/",
    redirect: "/gemeinden/1",
    component: GemeindePage,
  },
  {
    path: "/gemeinden/:wahlId",
    name: "Gemeinden",
    component: GemeindePage,
  },
  {
    path: "/kandidaten/:wahlId/:gemeindeId",
    name: "Kandidaten",
    component: KandidatPage,
  },
  {
    path: "/ergebnis/:wahlId/:gemeindeId",
    name: "Diagramm",
    component: DiagrammPage,
    props: true,
  },
  {
    path: "/teilnahmebedingungen",
    name: "bedingungen",
    component: BedingungenPage,
  },
];

const router = createRouter({
  history: createWebHistory("/../"),
  routes,
});

export default router;
